import React from "react"

import Button from "../components/button"
import SectionHeader from "./section-header"
import logo from "../images/nova-logo.png"
import feature from "../images/feature.png"

const CallToAction = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    {/* <SectionHeader
      title="About Us"
      description="We are a multinational engineering services and technology solutions provider. We solve complex problems that really matter."
    /> */}
    <content>
      {/* <div>
        <a href="https://novasystems.com">
          <img src={logo} alt="Something about this image..." />
        </a>
      </div> */}
      <div>
        <a href="https://novasystems.com">
          <img src={feature} alt="Something about this image..." />
        </a>
      </div>
      <br />
    </content>
    {/* <Button url="https://www.channelnewsasia.com/news/singapore/trial-on-drone-traffic-management-system-concludes-after-2-years-14394072">Latest News</Button> */}
  </div>
)

export default CallToAction
