import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Content0 from "../components/contents/content0"
import Content1 from "../components/contents/content1"
import Content2 from "../components/contents/content2"
import Content3 from "../components/contents/content3"
import Content4 from "../components/contents/content4"
import Content5 from "../components/contents/content5"
import CallToAction from "../components/cta"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Hero />
    <Content5 />
    <Content0 />
    <Content1 />
    <Content2 />
    <Content4 />
    <Content3 />
    <CallToAction />
  </Layout>
)

export default IndexPage
