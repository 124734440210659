import React from "react"

import feature from "../../images/sections/research-bg.png"
import SectionHeader from "../section-header"
import { COLORS } from "../../styles/constants"

const Content2 = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Conformance Monitoring Service"
      description="Nova USS manages the conformance requirements of all flight operations in real-time."
    />
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div>
        <h3>Real-time Monitoring</h3>
        <p style={{ color: COLORS.gray }}>
          Supports LTE-based in-flight monitoring and operational state management.
        </p>
      </div>
      <div>
        <img src={feature} alt="Something about this image..." />
      </div>
    </content>
  </div>
)

export default Content2
