import React from "react"

import feature from "../../images/sections/route-bg.png"
import SectionHeader from "../section-header"
import { COLORS } from "../../styles/constants"

const Content0 = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Flight Planning Service"
      description="Nova USS provides a flexible flight planning and authorisation workflow for UAS Operators."
    />
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div>
        <h3>Free-space Routing</h3>
        <p style={{ color: COLORS.gray }}>
          Supports point-to-point and waypoint-based flight planning modes.
        </p>
      </div>
      <div>
        <img src={feature} alt="Something about this image..." />
      </div>
    </content>
  </div>
)

export default Content0
