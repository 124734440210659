import React from "react"

import feature from "../../images/sections/lte-bg.png"
import SectionHeader from "../section-header"
import { COLORS } from "../../styles/constants"

const Content4 = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Remote Identification Service"
      description="Nova USS implements the latest ASTM standards for remote identification and tracking."
    />
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div>
        <h3>Network RID</h3>
        <p style={{ color: COLORS.gray }}>
          Current hardware supports the Net-RID implementation of Remote ID.
        </p>
      </div>
      <div>
        <img src={feature} alt="Something about this image..." />
      </div>
    </content>
  </div>
)

export default Content4
