import React from "react"

import feature from "../../images/sections/square-bg.png"
import SectionHeader from "../section-header"
import { COLORS } from "../../styles/constants"

const Content3 = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Constraint Management Service"
      description="Nova USS provides the Airspace Manager the ability to manage airspace restrictions and constraints."
    />
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div>
        <h3>4D Airspace Restrictions</h3>
        <p style={{ color: COLORS.gray }}>
          Supports UAS volume restrictions and geographical zone definitions.
        </p>
      </div>
      <div>
        <img src={feature} alt="Something about this image..." />
      </div>
    </content>
  </div>
)

export default Content3
